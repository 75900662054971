
import { Vue, Options } from 'vue-class-component';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { BaseIcon, PageLoading } from '@/lib/components';
import { MeniconSurveyResultsService } from '@/services/api';
import { SurveyResult } from '@/models';
import { i18n } from '@/i18n/i18n';
import { useNotificationStore } from '@/stores/notification.store';

dayjs.extend(utc);

@Options({
  props: {
    patientId: {
      type: String,
      required: true
    },

    surveyResultId: {
      type: String,
      required: true
    }
  },
  components: {
    BaseIcon,
    PageLoading
  }
})
export default class VirtualDoctorSurveyResultPage extends Vue {
  patientId!: string;
  surveyResultId!: string;
  loading = true;
  service = new MeniconSurveyResultsService(this.patientId);
  notificationStore = useNotificationStore();

  surveyResult: SurveyResult | null = null;

  get locale(): string {
    return i18n.global.locale.value;
  }

  get type(): string {
    if (this.surveyResult) {
      const locale: string =
        Object.keys(this.surveyResult.type).find((l) => l.toUpperCase() === this.locale.split('-')[0].toUpperCase()) ||
        'EN';
      return this.surveyResult.type[locale];
    }
    return '';
  }

  get results(): { question: string; answer: string }[] {
    if (this.surveyResult?.results.length) {
      const locale: string =
        Object.keys(this.surveyResult.results[0].question).find(
          (l) => l.toUpperCase() === this.locale.split('-')[0].toUpperCase()
        ) || 'EN';
      return this.surveyResult.results.map((result) => ({
        question: result.question[locale],
        answer: result.answer[locale]
      }));
    }
    return [];
  }

  async created() {
    await this.getSurveyResult();
  }

  async getSurveyResult() {
    try {
      this.loading = true;
      this.surveyResult = await this.service.fetch(this.surveyResultId);
    } catch (error) {
      await this.notificationStore.addErrorNotification({
        title: this.$t('custom.menicon.virtual-doctor.fetch-survey-results-error')
      });
    } finally {
      this.loading = false;
    }
  }

  formatDate(isoDate: string) {
    return this.$d(new Date(isoDate), 'shortMonth');
  }
}
