import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex w-full items-center justify-center"
}
const _hoisted_2 = {
  key: 1,
  class: "max-w-6xl mx-auto my-12 px-6"
}
const _hoisted_3 = { class: "flex justify-between" }
const _hoisted_4 = { class: "text-lg font-bold text-gray-900" }
const _hoisted_5 = { class: "text-xs text-gray-500 font-semibold text-right" }
const _hoisted_6 = {
  key: 0,
  class: "text-lg font-semibold text-gray-900"
}
const _hoisted_7 = { class: "mt-10 p-10 bg-white border rounded-lg shadow" }
const _hoisted_8 = { class: "font-bold text-gray-900" }
const _hoisted_9 = { class: "mt-6 p-4 text-gray-900 bg-gray-100 rounded" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageLoading = _resolveComponent("PageLoading")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_PageLoading)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", null, [
              _createVNode(_component_router_link, {
                to: `/patients/${_ctx.patientId}/virtual-doctor/survey-results`,
                class: "font-semibold text-gray-500 mb-2"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_BaseIcon, { name: "arrow-left" }),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('platform.common.back')), 1)
                ]),
                _: 1
              }, 8, ["to"]),
              _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.type), 1)
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('platform.common.completed-date')), 1),
              (_ctx.surveyResult)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.formatDate(_ctx.surveyResult.date)), 1))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.results, (result, i) => {
              return (_openBlock(), _createElementBlock("div", {
                key: i,
                class: _normalizeClass({ 'mt-8': i > 0 })
              }, [
                _createElementVNode("p", _hoisted_8, _toDisplayString(result.question), 1),
                _createElementVNode("p", _hoisted_9, _toDisplayString(result.answer), 1)
              ], 2))
            }), 128))
          ])
        ]))
  ]))
}